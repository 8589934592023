.rect {
    width: fit-content;
    height: fit-content;
    background-color: #ddd;
    padding: 10px;
    border-radius: 3px;
    border: solid 1px;
    opacity: 0.95;
}

.notes-header {
    text-align: center;
}

.hline {
    border-top: 1px solid rgb(110, 110, 110);
    width: 100%;
    margin-top: 3px;
    margin-bottom: 2px;
}