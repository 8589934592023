/* .VictoryBarChartLineComboContainer {
	margin-top: 100px;
} */
.VictoryBarChartLineComboContainer svg:not(:root) {
	overflow: initial;
}

/* legend */
.VictoryBarChartLineComboContainerLegend {
	display: flex;
	padding-left: 50px;
}
.VictoryBarChartLineComboContainerLegend-Entry {
	display: flex;
	padding-right: 30px;
}
.VictoryBarChartLineComboContainerLegend-Entry-Color {
	width: 30px;
	height: 15px;
	align-self: center;
	margin-right: 5px;
}
.VictoryBarChartLineComboContainerLegend-Entry-Color-Line {
	height: 3px;
	width: 30px;
	align-self: center;
	margin-right: 5px;
}
.VictoryBarChartLineComboContainerLegend-Entry-Title {
	align-self: center;
	font-size: 13px;
}
.VictoryBarChartLineComboContainerInnerChart {
	padding-left: 15px;
}