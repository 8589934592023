:root {
	--tint-color: #ee7203;
	--headline-top-padding: 30px;
	--grey: #c3c3c3;
	--color-wohungen: #c38e75;
	--color-gewerbe: #ffe5b4;
	--color-parken: #fcbc86;
	--color-sonstige: #aa9e88;
	--table-font-size: 24px;
}

.App {
	position: relative;
	max-width: 800px;
	left: 50%;
	transform: translateX(-50%);
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* for the testcases */
.testView-Headline {
	border-bottom: 1px solid black;
	margin-top: 50px;
}
.testview ul{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.testview ul li{
	float: left; 
	margin: 15px;
}
.test-OverviewTile-Container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.ChartSeparator {
	height: 50px;
	width: 100%;
}
.testViewContainer {
	border: 1px solid red;
	border: 1px solid red;
}
.spacer {
	width: 100%;
	height: 30px;
}
.test-BarLineChart-container {
	margin-bottom: 50px;
}
.separator {
	margin-top: 20px;
}