/* OverviewTile */
.OverviewTile {
	/* width: calc(50% - 5px); */
	border: 1px solid gray;
	border-radius: 3px;
	height: 100px;
	margin-bottom: 10px;
	position: relative;
}
.OverviewTile-Title {
	padding-top: 10px;
	color: gray;
	font-size: 13px;
	padding-left: 10px;
}
.OverviewTile-Value {
	font-size: 46px;
	padding-left: 10px;
	font-weight: 700;
	position: absolute;
	bottom: 0px;
}
.OverviewTile-ValueSmall {
	font-size: 22px;
	padding-right: 10px;
	padding-top: 5px;
	font-weight: 400;
	position: absolute;
	top: 0px;
	right: 0px;
}

/* 2 tiles in a row */
.OverviewTilesNum2 {
	display: flex;
	justify-content: space-between;
}
.OverviewTilesNum2 .OverviewTile {
	width: calc(50% - 10px);
}

/* 3 tiles in a row */
.OverviewTilesNum3 {
	display: flex;
	justify-content: space-between;
}
.OverviewTilesNum3 .OverviewTile {
	width: calc(33.33333% - 10px);
}

/* ----------------- EED Dashboard ---------------------------------------------------- */

.OverviewTile-Value {
    font-size: 29px;
    font-weight: 700;
}
.OverviewTile-ValueSmall {
    font-size: 18px;
    font-weight: 400;
}

@media (max-width:350px) {
    .OverviewTile-Value {
        font-size: 25px;
        font-weight: 700;
    }
    .OverviewTile-ValueSmall {
        font-size: 14px;
        font-weight: 400;
    }
	.OverviewTile-Title {
		font-size: 11px
	}
}

@media (max-width:375px) and (min-width:351px) {
    .OverviewTile-Value {
        font-size: 27px;
        font-weight: 700;
    }
    .OverviewTile-ValueSmall {
        font-size: 16px;
        font-weight: 400;
    }
}

@media (min-width:1025px) {
    .OverviewTile-Value {
        font-size: 38px;
        font-weight: 700;
    }
    .OverviewTile-ValueSmall {
        font-size: 20px;
        font-weight: 400;
    }
}

@media (min-width:1281px) {
    .OverviewTile-Value {
        font-size: 46px;
        font-weight: 700;
    }
    .OverviewTile-ValueSmall {
        font-size: 22px;
        font-weight: 400;
    }
}