.GenericTable table {
	width: 100%;
	border-collapse: collapse;
	padding: 10px;
}
.GenericTable td {
	text-align: center;
	border-bottom: 1px solid black;
}
.GenericTable tr:last-child td {
	border-bottom: none;
}
td.GenericTable-rowLabel {
	text-align: left;
}
.GenericTable {
	padding: 10px;
}