.PieChartContainer {
	display: flex;
	margin-bottom: 25px;
}

/* legend */
.PieChart-Legend-Entry {
	display: flex;
}
.PieChart-Legend-Entry-Color {
	background-color: rgb(137, 186, 23);
	margin-right: 5px;
	width: 23px;
	height: 15px;
	align-self: center;
}
.PieChart-Legend {
	align-self: flex-end;
	margin-left: 60px;
}
.PieChart-Legend-Entry-Title {
	font-size: 13px;
}