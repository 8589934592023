.StackingPlanLegend {
	font-size: 1vw;
	font-family: "Gill Sans", "Gill Sans MT", Ser­avek, "Trebuchet MS", sans-serif;
}

.StackingPlanLegend th {
	padding-left: 12px;
	padding-right: 12px;
	min-width: 100px;
	font-weight: initial;
}

.StackingPlanLegend thead th {
	font-weight: bold;
}

.StackingPlanLegend thead {
	background-color: darkblue;
	color: white;
}
