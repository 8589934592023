.ContainerEedDashboard {
    width: 98vw;
    height: 98vh;
    position: relative;
    left: calc(-50vw + 50%);
    display: grid;
        grid-template-columns: 30% 70%;
        grid-template-rows: 80% 20%;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
}

.ContainerEedMobile{
    margin: 5px;
}

.ContainerOverviewLatestData{
    grid-row: 1;
    grid-column: 1;
	border: 1px solid gray;
	border-radius: 3px;
    padding: 5px;
    display: grid;
        grid-template-rows: 8% 5% 35% 52%;
        grid-template-columns: 50% 50%;
}

.ContainerOverviewLatestData-mobile{
    grid-row: 1;
    grid-column: 1;
	border: 1px solid gray;
	border-radius: 3px;
    padding: 5px;
    display: grid;
        grid-template-rows: 8% 5% 35% 52%;
        grid-template-columns: 50% 50%;
}



.ContainerOverviewLatestData-Buttons{
    grid-row: 2 / 3;
    grid-column: 1 / 3;
}

.OverviewLatestDataTitle{
    position: relative;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    font-size: 18px;
	font-weight: 400;
}

.OverviewLatestDataMonth{
    position: relative;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    font-size: 22px;
	font-weight: 400;
    text-align: right;
}

.ContainerOverviewLatestData-Widgets{
    grid-row: 3 / 4;
    grid-column: 1 / 3;
}

.ContainerOverviewLatestData-WaterComboPieChart{
    grid-row: 4 / 5;
    grid-column: 1 / 3;
}


.ContainerYearlyDataCharts{
    border: 1px;
    padding: 5px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 4% 48% 48%;
}

.ContainerYearSelectionButtons{
    grid-row: 1;
    grid-column: 1;
}

.ContainerTripleComparisonBarChart-heating{
    grid-row: 2;
    grid-column: 1;
}

.TripleComparisonBarChart{
    width: 100%;
    height: 100%;
}

.TripleComparisonBarChart-Title{
    text-align: center;
}

.ContainerTripleComparisonBarChart-cooling{
    grid-row: 2;
    grid-column: 2;
}

.ContainerWaterComparisonStackBar{
    grid-row: 3;
    grid-column: 1 / 3;
}

.WaterComparisonStackBar-Title{
    text-align: center;
}

.WaterComparisonStackBar{
    width: 100%;
    height: 100%;
}

.YearlyDataLegend{
    position: relative;
    right: -80%;
}

.ContainerSelectableServiceTable{
    grid-row: 2;
    grid-column: 1 / 3;
    padding-top: 30px;
}

.SelectableServiceTable-transposed{
    padding-top: 40px;
}

.ContainerYearlyData-mobile{
	border: 1px solid gray;
	border-radius: 3px;
    padding: 5px;
    margin-top: 5px;
}

.yearButton {
    background-color: gray;
    border: none;
    color: white;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 1px;
    border-radius: 3px;
}

.CompButton {
    background-color: gray;
    border: none;
    color: white;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 1px;
    border-radius: 3px;
}

.OverviewTile-ValueSmall{
    animation: highlight 5s;
}

.WaterComboPieChart-Legend{
    animation: highlight-svg 5s;
}

@keyframes highlight {
    from { 
        color: yellow;
    }
    to {
        color: black;
    }
}

@keyframes highlight-svg {
    from { 
        fill: yellow;
    }
    to {
        fill: black;
    }
}